<template>
  <el-aside class="aside">
    <el-menu router class="el-menu-vertical-demo" :collapse="isCollapse" unique-opened>
      <div class="logo-nav">
        <img src="../../assets/logo.svg" alt="Alter Portal Logo">
        <div>
          {{ getFio }}
        </div>
      </div>
      <el-menu-item v-for="item in menu" :key="item.index" :index="item.index">
        <i :class="item.icon"></i>
        <span slot="title">{{ item.title }}</span>
      </el-menu-item>
      <el-submenu index="agreement" v-if="canWatchAgreement && !isRolePetroleum">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>Договора</span>
        </template>
        <el-menu-item index="/company/agreement/agreement/true">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">Подписанные</span>
        </el-menu-item>
        <el-menu-item index="/company/agreement/agreement/false">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">Не подписанные</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu index="agreement2" v-if="canWatchAgreement && isRolePetroleum">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>Договора</span>
        </template>
        <el-menu-item index="/company/agreementpet/agreement">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">Подписанные</span>
        </el-menu-item>
        <el-menu-item index="/company/agreement/agreement/false">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">Не подписанные</span>
        </el-menu-item>
      </el-submenu>
      <el-submenu v-if="isPtc && canWatchDocuments" index="ertys">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>ТОО “Ертыс-сервис”</span>
        </template>
        <el-submenu index="ertys1">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Полученные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20,vu19,vu20a?bin=1911400253455">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20?bin=1911400253455">Ву-20</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20a?bin=1911400253455">Ву-20а</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu19?bin=1911400253455">Ву-19</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/act?bin=1911400253455">Акт</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="ertys2">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Отправленные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/processing,vrd?bin=1911400253455">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/processing?bin=1911400253455">Заявка на обработку</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vrd?bin=1911400253455">Заявка на ВРД</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-submenu>

      <el-submenu v-if="isPtc && canWatchDocuments" index="ertys3">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>ТОО “Ертыс-сервис” (Архив)</span>
        </template>
        <el-submenu index="ertys4">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Полученные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20,vu19,vu20a?bin=070240009538">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20?bin=070240009538">Ву-20</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20a?bin=070240009538">Ву-20а</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu19?bin=070240009538">Ву-19</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/act?bin=070240009538">Акт</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="ertys5">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Отправленные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/processing,vrd?bin=070240009538">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/processing?bin=070240009538">Заявка на обработку</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vrd?bin=070240009538">Заявка на ВРД</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-submenu>

      <el-submenu v-if="isPtc && canWatchDocuments" index="kazykurt">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>ТОО “Казыкурт-ЮГ”</span>
        </template>
        <el-submenu index="kaz1">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Полученные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20,vu19,vu20a?bin=1911400253454">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20?bin=1911400253454">Ву-20</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20a?bin=1911400253454">Ву-20а</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu19?bin=1911400253454">Ву-19</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="kaz2">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Отправленные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/processing,drKr?bin=1911400253454">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/processing?bin=1911400253454">Заявка на обработку</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/drKr?bin=1911400253454">Заявка на ДрКР</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-submenu>
      <el-submenu v-if="isPtc && canWatchDocuments" index="batys">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>ТОО “Batys Petroleum”</span>
        </template>
        <el-submenu index="kaz1">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Полученные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20,vu19,vu20a?bin=1911400253453">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20?bin=1911400253453">Ву-20</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu20a?bin=1911400253453">Ву-20а</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/vu19?bin=1911400253453">Ву-19</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="kaz2">
          <template slot="title">
            <i class="el-icon-document"></i>
            <span>Отправленные</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/processing,raisingApplication,sendApplication,uncreditedWagons,preparationApplication,inspection,tor?bin=150540023006">Все документы</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/processing?bin=1911400253453">Заявка на обработку</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/raisingApplication?bin=1911400253453">Заявка на поднятие состава</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/sendApplication?bin=1911400253453">Заявка к отправке</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/uncreditedWagons?bin=1911400253453">Уведомление на нераскредитованных вагонов</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/preparationApplication?bin=1911400253453">Заявка на подготовку ТС</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/inspection?bin=1911400253453">Заявка на Осмотр</el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group>
            <el-menu-item index="/company/documents/tor?bin=1911400253453">Заявка на ТОР</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </el-submenu>

      <el-submenu v-if="isCompanyUserOrAdmin && canWatchDocuments && !isPtc" index="received">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>Полученные</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/vu20,vu19,vu20a">Все документы</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/vu20">Ву-20</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/vu20a">Ву-20а</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/vu19">Ву-19</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group v-if="isUserErtys">
          <el-menu-item index="/company/documents/act">Акт</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu v-if="isCompanyUserOrAdmin && !isUserErtys && !isUserKazykurt && canWatchTickets && !isPtc" index="sended">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>Отправленные</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/processing,raisingApplication,sendApplication,uncreditedWagons,preparationApplication,inspection,tor">Все документы</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/processing">Заявка на обработку</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/raisingApplication">Заявка на поднятие состава</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/sendApplication">Заявка к отправке</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/uncreditedWagons">Уведомление на нераскредитованных вагонов</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/preparationApplication">Заявка на подготовку ТС</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/inspection">Заявка на Осмотр</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/tor">Заявка на ТОР</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu v-if="isCompanyUserOrAdmin && isUserErtys && canWatchTickets" index="sended">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>Отправленные</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/processing,vrd">Все документы</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/processing">Заявка на обработку</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/vrd">Заявка на ВРД</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu v-if="isCompanyUserOrAdmin && isUserKazykurt && canWatchTickets" index="sended">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>Отправленные</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/processing,drKr">Все документы</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/processing">Заявка на обработку</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/drKr">Заявка на ДрКР</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="/company/documents/externalCleaning">Заявка на наружную мойку</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
<!--      <el-menu-item index="/super/services">-->
<!--        <i class="el-icon-s-unfold"></i>-->
<!--        <span slot="title">Сервисы</span>-->
<!--      </el-menu-item>-->
<!--      <el-menu-item index="/super/users">-->
<!--        <i class="el-icon-user-solid"></i>-->
<!--        <span slot="title">Пользователи</span>-->
<!--      </el-menu-item>-->
      <el-menu-item index="/login-page">
        <span slot="title">Выйти</span>
      </el-menu-item>
<!--      <el-menu-item index="/profile">-->
<!--        <i class="el-icon-setting"></i>-->
<!--        <span slot="title">Настройки</span>-->
<!--      </el-menu-item>-->
<!--      <div style="display: flex; justify-content: center; margin-top: 10px">-->
<!--        <el-button @click="$router.replace('/login-page')" type="primary" icon="el-icon-arrow-left" circle></el-button>-->
<!--        <span slot="title">Выйти</span>-->
<!--      </div>-->
    </el-menu>
  </el-aside>
</template>

<script>
import authorizationAPI from '@/modules/api/authorization'
import { canWatchTickets, canWatchDocuments, canWatchAgreement } from '@/modules/permissions/permissions'
export default {
  data () {
    return {
      isCollapse: false,
      menu: []
    }
  },
  mounted () {
    this.initNavigationByRole()
  },
  computed: {
    getFio () {
      return authorizationAPI.getFio()
    },
    isPtc () {
      return authorizationAPI.isRolePtc()
    },
    isUserErtys () {
      return authorizationAPI.isRoleErtys()
    },
    isUserKazykurt () {
      return authorizationAPI.isRoleKazykurt()
    },
    isRolePetroleum () {
      return authorizationAPI.isRolePetroleum()
    },
    isCompanyUserOrAdmin () {
      return !authorizationAPI.isRoleSuperAdmin()
    },
    canWatchTickets () {
      return canWatchTickets(authorizationAPI.getRole())
    },
    canWatchDocuments () {
      return canWatchDocuments(authorizationAPI.getRole())
    },
    canWatchAgreement () {
      console.log(authorizationAPI.getRole())
      console.log('canWatchAgreement(authorizationAPI.getRole())', canWatchAgreement(authorizationAPI.getRole()))
      return canWatchAgreement(authorizationAPI.getRole())
    }
  },
  methods: {
    initNavigationByRole () {
      if (authorizationAPI.isRoleSuperAdmin()) {
        this.menu = [
          {
            index: '/super/documents',
            icon: 'el-icon-document',
            title: 'Документы'
          },
          {
            index: '/super/services',
            icon: 'el-icon-s-unfold',
            title: 'Сервисы'
          },
          {
            index: '/super/users',
            icon: 'el-icon-user-solid',
            title: 'Пользователи'
          }
        ]
      } else if (authorizationAPI.isRoleCompanyAdmin()) {
        this.menu = [
          {
            index: '/company/checkSign',
            icon: 'el-icon-edit-outline',
            title: 'Проверка подписи'
          },
          {
            index: '/company/users',
            icon: 'el-icon-user-solid',
            title: 'Пользователи'
          }
        ]
      } else if (authorizationAPI.isRoleUser()) {
        this.menu = [
          {
            index: '/company/checkSign',
            icon: 'el-icon-edit-outline',
            title: 'Проверка подписи'
          }
        ]
      }
      if (authorizationAPI.isRolePtc()) {
        this.menu.push({
          index: '/company/register',
          icon: 'el-icon-document',
          title: 'Реестр с расчетами'
        })
      }
    }
  }
}
</script>

<style>
.aside {
  background-color: rgb(238, 241, 246);
  /*max-width: 250px;*/
}

.el-menu-vertical-demo {
  min-height: 100%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  /*width: 200px;*/
  min-height: 100%;
}

.logo-nav {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 20px;
  margin-top: 20px !important;
  padding-left: 10px;
}

.logo-nav img {
  height: 60px;
}
.el-submenu .el-menu-item {
  font-size: 14px;
  padding: 0;
}
.el-menu-item-group__title {
  padding: 0 !important;
}

</style>
