import authorizationAPI from '@/modules/api/authorization'

const permissions = {
  ROLE_ALL: {
    canWatchDocuments: true,
    canSignDocuments: true,
    canWatchTickets: true,
    canSignTickets: true,
    canCancelTickets: true,
    canCreateTickets: true,
    canWatchAgreement: true
  },
  ROLE_CREATE_TICKETS: {
    canWatchDocuments: true,
    canSignDocuments: false,
    canWatchTickets: true,
    canSignTickets: true,
    canCancelTickets: true,
    canCreateTickets: true,
    canWatchAgreement: false
  },
  ROLE_SIGN_DOCS: {
    canWatchDocuments: true,
    canSignDocuments: true,
    canWatchTickets: false,
    canSignTickets: false,
    canCancelTickets: false,
    canCreateTickets: false,
    canWatchAgreement: false
  },
  ROLE_VIEW_DOCS: {
    canWatchDocuments: true,
    canSignDocuments: false,
    canWatchTickets: false,
    canSignTickets: false,
    canCancelTickets: false,
    canCreateTickets: false,
    canWatchAgreement: false
  },
  ROLE_VIEW_TICKET: {
    canWatchDocuments: true,
    canSignDocuments: false,
    canWatchTickets: true,
    canSignTickets: false,
    canCancelTickets: false,
    canCreateTickets: false,
    canEditTickets: false,
    canWatchAgreement: false
  },
  ROLE_USER: {
    canWatchDocuments: true,
    canSignDocuments: true,
    canWatchTickets: true,
    canSignTickets: true,
    canCancelTickets: true,
    canCreateTickets: true,
    canWatchAgreement: true
  },
  ROLE_COMPANY_ADMIN: {
    canWatchDocuments: true,
    canSignDocuments: true,
    canWatchTickets: true,
    canSignTickets: true,
    canCancelTickets: true,
    canCreateTickets: true,
    canWatchAgreement: true
  },
  ROLE_AGREEMENT: {
    canWatchDocuments: false,
    canSignDocuments: false,
    canWatchTickets: false,
    canSignTickets: false,
    canCancelTickets: false,
    canCreateTickets: false,
    canWatchAgreement: true
  }
}

function canWatchDocuments (role) {
  if (role && permissions[role]) {
    return permissions[role].canWatchDocuments
  }
  return false
}

function canSignDocuments (role) {
  if (role && permissions[role]) {
    return permissions[role].canSignDocuments && !authorizationAPI.isRolePtc()
  }
  return false
}

function canWatchTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canWatchTickets
  }
  return false
}

function canSignTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canSignTickets && !authorizationAPI.isRolePtc()
  }
  return false
}

function canCancelTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canCancelTickets && !authorizationAPI.isRolePtc()
  }
  return false
}

function canCreateTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canCreateTickets && !authorizationAPI.isRolePtc()
  }
  return false
}

function canEditTickets (role) {
  if (role && permissions[role]) {
    return permissions[role].canEditTickets && !authorizationAPI.isRolePtc()
  }
  return false
}

function canWatchAgreement (role) {
  if (role && permissions[role]) {
    return permissions[role].canWatchAgreement
  }
  return false
}

export { canWatchAgreement, canWatchDocuments, canSignDocuments, canWatchTickets, canSignTickets, canCancelTickets, canCreateTickets, canEditTickets }
