import axios from 'axios'
import { MAIN_API } from './apiUrl'
import router from '@/router'

const authorizationAPI = {
  logIn: function (login, password) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/signin',
        data: { password: password, username: login, refreshToken: null },
        headers: { 'Content-Type': 'application/json' },
        method: 'POST'
      }).then((response) => {
        sessionStorage.setItem('token', response.data.token)
        sessionStorage.setItem('username', response.data.username)
        sessionStorage.setItem('companyBin', response.data.company?.bin)
        sessionStorage.setItem('companyId', response.data.company?.id)
        sessionStorage.setItem('loginUserInfo', JSON.stringify(response.data))
        sessionStorage.setItem('roleName', getRole(response.data.roles))
        if (this.isRoleSuperAdmin()) {
          router.push('/super/documents')
        } else {
          router.push('/dashboard')
        }
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  sigUp: function (data) {
    return new Promise((resolve, reject) => {
      axios({
        url: MAIN_API + '/signup',
        data,
        headers: { 'Content-Type': 'application/json' },
        method: 'POST'
      }).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  logOut: function () {
    sessionStorage.clear()
  },
  getRole: function () {
    return sessionStorage.getItem('roleName')
  },
  getFio: function () {
    const name = JSON.parse(sessionStorage.getItem('loginUserInfo')).name ? JSON.parse(sessionStorage.getItem('loginUserInfo')).name : ''
    const lastName = JSON.parse(sessionStorage.getItem('loginUserInfo')).lastName ? JSON.parse(sessionStorage.getItem('loginUserInfo')).lastName[0] : ''
    const patronymic = JSON.parse(sessionStorage.getItem('loginUserInfo')).patronymic ? JSON.parse(sessionStorage.getItem('loginUserInfo')).patronymic[0] : ''
    return name + ' ' + lastName + '. ' + patronymic + '. '
  },
  // getFio: function () {
  //   return ''
  // },
  isAuthenticated: function () {
    return !!sessionStorage.getItem('token')
  },
  isRoleSuperAdmin: function () {
    return sessionStorage.getItem('roleName') === 'ROLE_SUPER_ADMIN'
  },
  isRoleCompanyAdmin: function () {
    return sessionStorage.getItem('roleName') === 'ROLE_COMPANY_ADMIN'
  },
  isRoleUser: function () {
    return sessionStorage.getItem('roleName') === 'ROLE_USER' || sessionStorage.getItem('roleName') === 'ROLE_ALL'
  },
  isRoleErtys: function () {
    return sessionStorage.getItem('companyBin') === '070240009538' || sessionStorage.getItem('companyBin') === '091140012144' || sessionStorage.getItem('companyBin') === '051040000378' || sessionStorage.getItem('companyBin') === '1911400253455'
  },
  isRoleKazykurt: function () {
    return sessionStorage.getItem('companyBin') === '1911400253454'
  },
  isRoleBatys: function () {
    return sessionStorage.getItem('companyBin') === '1911400253453'
  },
  isRolePetroleum: function () {
    return sessionStorage.getItem('companyBin') === '020240000367'
  },
  isRoleAgreement () {
    return sessionStorage.getItem('roleName') === 'ROLE_AGREEMENT'
  },
  isRolePtc: function () {
    return sessionStorage.getItem('companyBin') === '191140025345'
  },
  isRoleArchive: function () {
    return sessionStorage.getItem('companyBin') === '070240009538'
  },
  getUsersCompanyBin: function () {
    return sessionStorage.getItem('companyBin')
  },
  getUsersCompanyId: function () {
    return sessionStorage.getItem('companyId')
  }
}

export function getRole (roles) {
  console.log('roles,', roles)
  let role = ''
  if (roles.filter(x => x.name === 'ROLE_COMPANY_ADMIN').length > 0) {
    role = 'ROLE_COMPANY_ADMIN'
  } else if (roles.filter(x => x.name === 'ROLE_SUPER_ADMIN').length > 0) {
    role = 'ROLE_SUPER_ADMIN'
  } else if (roles.filter(x => x.name === 'ROLE_ALL').length > 0) {
    role = 'ROLE_ALL'
  } else if (roles.filter(x => x.name === 'ROLE_CREATE_TICKETS').length > 0) {
    return 'ROLE_CREATE_TICKETS'
  } else if (roles.filter(x => x.name === 'ROLE_SIGN_DOCS').length > 0) {
    return 'ROLE_SIGN_DOCS'
  } else if (roles.filter(x => x.name === 'ROLE_VIEW_DOCS').length > 0) {
    return 'ROLE_VIEW_DOCS'
  } else if (roles.filter(x => x.name === 'ROLE_VIEW_TICKET').length > 0) {
    return 'ROLE_VIEW_TICKET'
  } else if (roles.filter(x => x.name === 'ROLE_AGREEMENT').length > 0) {
    return 'ROLE_AGREEMENT'
  } else if (roles.filter(x => x.name === 'ROLE_USER').length > 0) {
    return 'ROLE_USER'
  }
  return role
}

export default authorizationAPI
