<template>
  <div>
    <el-dialog title="Создание документа" :visible.sync="createDialogVisible" width="600px" :close-on-click-modal="false">
      <div v-if="!isRoleErtys && !isRoleKazykurt">
        <el-radio-group v-model="creationDocType">
          <el-radio-button label="processing">Заявка на обработку</el-radio-button>
          <el-radio-button label="raisingApplication">Заявка на поднятие состава</el-radio-button>
          <el-radio-button label="sendApplication">Заявка к отправке</el-radio-button>
          <el-radio-button label="uncreditedWagons">Уведомление на нераскредитованных вагонов</el-radio-button>
          <el-radio-button label="preparationApplication">Заявка на подготовку ТС</el-radio-button>
          <el-radio-button label="inspection">Заявка на Осмотр</el-radio-button>
          <el-radio-button label="tor">Заявка на ТОР</el-radio-button>
        </el-radio-group>
      </div>
      <div v-if="isRoleErtys">
        <el-radio-group v-model="creationDocType">
          <el-radio-button label="processing">Заявка на обработку</el-radio-button>
          <el-radio-button label="vrd">Заявка на ВРД</el-radio-button>
        </el-radio-group>
      </div>
      <div v-if="isRoleKazykurt">
        <el-radio-group v-model="creationDocType">
          <el-radio-button label="processing">Заявка на обработку</el-radio-button>
          <el-radio-button label="drKr">Заявка на ДрКР</el-radio-button>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialogVisible = false">Отмена</el-button>
            <el-button type="primary" @click="createDocument">Сохранить</el-button>
      </span>
    </el-dialog>
    <div style="display: flex; justify-content: space-between">
      <h2 class="page-title">Документы</h2>
      <div style="padding-right: 20px">
        <el-badge :value="notificantionCount" :max="99" class="item">
          <el-button disabled size="small" style="border-radius: 25px">Создано блоков</el-button>
        </el-badge>
      </div>
    </div>
    <el-card style="margin-bottom: 15px;">
      <el-row type="flex" class="row-bg" justify="space-between">
        <div>
          <el-date-picker
              v-model="dateRange"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="По"
              start-placeholder="Начало"
              end-placeholder="Конец"
              @change="getData"
              :picker-options="pickerOptions"
              :default-value="defaultDateRange">
          </el-date-picker>
        </div>
        <el-col :span="6">
          <div style="display: flex">
            <el-input v-model="search" placeholder="Поиск" style="margin-right: 5px;"></el-input>
            <el-button @click="getData" type="primary"><i class="el-icon-search" style="font-weight: bold"/></el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="6">
          <el-button v-if="canCreateTickets" size="medium" @click="openCreateDocument" type="basic" icon="el-icon-plus" style="margin-bottom: 20px;">Создать документ</el-button>
        </el-col>
      </el-row>
      <el-table
          v-loading="isLoading"
          :data="data"
          @sort-change="sortChange"
          @row-click="openDocument($event)"
          :row-class-name="tableRowClassName"
          :default-sort="{}"
          size="small"
          style="width: 100%">
        <el-table-column
            prop="doctype"
            label="Тип документа"
        >
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.doctype }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="docNumber"
            label="Номер документа"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.docNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="documentId"
            label="Id документа"
            sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.meta.documentId }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="sendedAt"
            label="Дата отправки"
            sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.created }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="senderCompany"
            label="Компания отправитель"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.senderCompany }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="receiverCompany"
            label="Компания получатель"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.receiverCompany }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="statusName"
            label="Статус"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.statusName }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalElements"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import blockchainApi from '@/modules/api/blockchain'
import authorizationAPI from '@/modules/api/authorization'
import date from '@/modules/directories/date/date'
import { canCreateTickets } from '@/modules/permissions/permissions'
import { endOfDay, format, startOfMonth } from 'date-fns'

export default {
  name: 'CompanyDocuments',
  components: {
  },
  data () {
    const today = new Date()
    const currentYear = today.getFullYear()
    const currentMonth = today.getMonth()
    const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1)
    const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1)
    const currentData = new Date()
    const fromDate = startOfMonth(currentData)
    const toDate = currentData
    return {
      isLoading: false,
      data: [],
      createDialogVisible: false,
      creationDocType: '',
      currentPage: 1,
      pageSize: 10,
      totalElements: 0,
      sortBy: '',
      orderBy: '',
      search: '',
      notificantionCount: 0,
      pickerOptions: {
        shortcuts: [{
          text: 'Неделя',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Месяц',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 месяца',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultDateRange: [firstDayOfPreviousMonth, firstDayOfCurrentMonth],
      value1: '',
      dateRange: [
        fromDate,
        toDate
      ]
    }
  },
  created () {
    this.pageSize = 50
  },
  mounted () {
    this.getData()
    this.getNotificationsCount()
  },
  computed: {
    hasBinProps () {
      return this.$route.query.bin
    },
    getCompanyBin () {
      return authorizationAPI.getUsersCompanyBin()
    },
    canCreateTickets () {
      return canCreateTickets(authorizationAPI.getRole())
    },
    isRoleErtys () {
      return authorizationAPI.isRoleErtys()
    },
    isRoleKazykurt () {
      return authorizationAPI.isRoleKazykurt()
    },
    getUsersCompanyBin () {
      return authorizationAPI.getUsersCompanyBin()
    },
    filteredData () {
      return this.data
    },
    getDocumentTypes () {
      if (this.$route.params.types) {
        return this.$route.params.types.split(',')
      }
      return []
    }
  },
  watch: {
    getDocumentTypes (val) {
      this.getData()
    }
  },
  methods: {
    getNotificationsCount () {
      blockchainApi.getNotificationsCount(this.getUsersCompanyBin).then(resp => {
        if (resp !== 0) {
          this.notificantionCount = resp
        }
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.data.body.documentWorkplaceRailcar.documentRailcar.rejectingReason) {
        return 'error-row'
      }
      return ''
    },
    openCreateDocument () {
      if (this.$route.params.types.includes(',') || this.$route.params.types === 'vu20' || this.$route.params.types === 'act' || this.$route.params.types === 'vu20a' || this.$route.params.types === 'vu19') {
        this.createDialogVisible = true
      } else {
        this.creationDocType = this.$route.params.types
        this.createDocument()
      }
    },
    sortChange ({ column, prop, order }) {
      console.log(prop, order)
      this.sortBy = prop
      if (order && order.includes('asc')) {
        this.orderBy = 'asc'
      } else if (order && order.includes('desc')) {
        this.orderBy = 'desc'
      } else {
        this.orderBy = ''
        this.sortBy = ''
      }
      this.getData()
    },
    handleSizeChange (event) {
      this.pageSize = event
      this.getData()
      console.log(event)
    },
    handleCurrentChange (event) {
      this.currentPage = event
      this.getData()
      console.log(event)
    },
    getData () {
      this.getNotificationsCount()
      this.isLoading = true
      const fromDate = format(new Date(this.dateRange[0]), 'yyyy-MM-dd HH:mm:ss')
      const toDate = format(endOfDay(new Date(this.dateRange[1])), 'yyyy-MM-dd HH:mm:ss')
      const companyBin = this.hasBinProps ? this.hasBinProps : this.getCompanyBin
      blockchainApi.getDocumentsPaginated(fromDate, toDate, this.currentPage, this.pageSize, this.getDocumentTypes, this.sortBy, this.orderBy, companyBin, this.search).then(resp => {
        this.totalElements = resp.total
        this.data = resp.data.filter(item => {
          try {
            JSON.parse(item.data)
          } catch (e) {
            return false
          }
          return true
        }).map(item => {
          item.created = item.createdAt ? date.getFormattedDate(item.createdAt) : ''
          if (item.meta) {
            switch (item.meta.doctype) {
              case 'vu20':
                item.doctype = 'ВУ-20'
                break
              case 'vu20a':
                item.doctype = 'ВУ-20a'
                break
              case 'vu19':
                item.doctype = 'ВУ-19'
                break
              case 'processing':
                item.doctype = 'Заявка на обработку'
                break
              case 'raisingApplication':
                item.doctype = 'Заявка на поднятие состава'
                break
              case 'sendApplication':
                item.doctype = 'Заявка к отправке'
                break
              case 'uncreditedWagons':
                item.doctype = 'Уведомление на нераскредитованных вагонов'
                break
              case 'preparationApplication':
                item.doctype = 'Заявка на подготовку ТС'
                break
              case 'inspection':
                item.doctype = 'Заявка на Осмотр'
                break
              case 'tor':
                item.doctype = 'Заявка на ТОР'
                break
              case 'vrd':
                item.doctype = 'Заявка на ВРД'
                break
              case 'drKr':
                item.doctype = 'Заявка на ДрКр'
                break
            }
          }
          if (item.status) {
            if (item.status.status === 'CREATED') {
              item.statusName = 'Создан'
            } else if (item.status.status === 'SENDED') {
              item.statusName = 'Отправлен'
            } else if (item.status.status === 'APPROVED') {
              item.statusName = 'Утвержден'
            } else if (item.status.status === 'RECEIVED') {
              item.statusName = 'Доставлен'
            } else if (item.status.status === 'REJECTED') {
              item.statusName = 'Отклонен'
            } else if (item.status.status === 'CANCELED') {
              item.statusName = 'Отменен'
            } else if (item.status.status === 'UPDATED') {
              item.statusName = 'Отредактирован'
            } else if (item.status.status === 'CONFIRMED') {
              item.statusName = 'Подтвержден'
            }
          }
          if (item.data) {
            item.data = JSON.parse(item.data)
            if (item.data.body.documentWorkplaceRailcar.documentRailcar) {
              item.docNumber = item.data.body.documentWorkplaceRailcar.documentRailcar.docNumber
              item.receiverCompany = item.data.body.documentWorkplaceRailcar.documentRailcar.receiverCompany
              item.senderCompany = item.data.body.documentWorkplaceRailcar.documentRailcar.senderCompany
            }
          }
          return item
        })
        const uniqueItems = {}
        this.data.forEach(item => {
          if (!uniqueItems[item.meta.documentId] || uniqueItems[item.meta.documentId].createdAt < item.createdAt) {
            uniqueItems[item.meta.documentId] = item
          }
        })
        this.data = Object.values(uniqueItems).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.isLoading = false
      })
    },
    createDocument () {
      this.$router.push('/company/create-ticket/' + this.creationDocType)
    },
    openDocument (data) {
      if (data.meta.doctype === 'vu20' || data.meta.doctype === 'vu20a') {
        this.$router.push({ path: '/company/create-vu20', query: { hash: data.hash } })
      } else if (data.meta.doctype === 'vu19') {
        this.$router.push({ path: '/company/create-vu19', query: { hash: data.hash } })
      } else {
        this.$router.push({ path: '/company/create-ticket/' + data.meta.doctype, query: { hash: data.hash } })
      }
    }
  }
}
</script>

<style>
.pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center
}
.el-table tr {
  cursor: pointer;
}
.el-table .error-row {
  background-color: #ffd8d8;
}
</style>
